import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, IconButton, TextField, Typography } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import { AddAPhoto } from '@mui/icons-material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useCms } from '../../utils/CMS';
import SmallDialog from 'components/SmallDialog';
import Toast from 'components/Toast';
import Dropdown from 'components/Dropdown';

import ProductImages from './ProductImages';
import {
  useGetProductTypeMutation,
  useTempReleaseProductMutation,
  useUpdateProductMutation,
  useValidateProductMutation,
} from 'api/apiSlice';
import ProductVideo from './ProductVideo';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 80,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(255, 255, 255)'
        : 'rgb(255, 255, 255)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: 'rgb(255, 255, 255)',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function GroupMenu({
  group,
  index,
  cat_index,
  update,
  add,
  cats,
  handleMoveProduct,
}) {
  const { updateSubCategory, removeProduct, getTree, changeSubCategoryParent } =
    useCms();
  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const [tempReleaseProduct] = useTempReleaseProductMutation();
  const [getProductType] = useGetProductTypeMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [validateProduct] = useValidateProductMutation();

  const [renameSubCategoryOpen, setRenameSubCategoryOpen] = useState(false);
  const [renameSubCategoryData, setRenameSubCategoryData] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState();

  const [status, setstatus] = useState(group.isProduction);
  const [price, setprice] = useState(group.price);
  const [featured, setfeatured] = useState(group.featured);

  const [category, setcategory] = useState();

  const [typeDialogOpen, setTypeDialogOpen] = useState(false);
  const [typeDialogData, setTypeDialogData] = useState();

  const [validationInfo, setvalidationInfo] = useState({});

  const [deletePromptOpen, setdeletePromptOpen] = useState(false);
  const [deletePromptData, setdeletePromptData] = useState(false);

  const product_types_names = [
    'human_male',
    'human_female',
    'dog_male',
    'dog_female',
    'cat_male',
    'cat_female',
  ];
  const [productType, setproductType] = useState({
    human: { male: false, female: false },
    dog: { male: false, female: false },
    cat: { male: false, female: false },
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (group) {
      setstatus(group.production ? 'released' : 'not released');
      setfeatured(group.featured ? 'yes' : 'no');
      setprice(group.price);
      setcategory(group.parent);
      if (group?.type) {
        setproductType(group.type);
      }
    }
  }, [group]);

  // RENAME SUB CATEGORY
  useEffect(() => {
    if (renameSubCategoryData) {
      let oldname = group.name;
      var newgroup = JSON.parse(JSON.stringify(group));
      newgroup.name = subCategoryName;
      newgroup.prompts = [];

      updateSubCategory(newgroup, oldname).then((resp) => {
        if (resp.success) {
          update('name', subCategoryName, index);
          setRenameSubCategoryData(false);
        } else {
          setAlert({
            show: true,
            severity: 'error',
            msg: resp.msg,
          });
        }
      });
    }
  }, [renameSubCategoryData]);

  // useEffect(() => {
  //   handleProductStatusChange();
  // }, [status]);

  const handleSubCategoryNameChange = (event) => {
    setSubCategoryName(event.target.value);
  };

  useEffect(() => {
    setAnchorEl(null);
    if (deletePromptData) {
      handleRemoveProduct();
    }
  }, [deletePromptData]);

  const handleRemoveProduct = () => {
    removeProduct(group.name).then((resp) => {
      if (resp.success) {
        setAlert({
          show: true,
          severity: 'success',
          msg: resp.msg,
        });
        update('delete', '', index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  useEffect(() => {
    console.log(typeDialogData);
    if (typeDialogData) {
      handlePostProductStatusChange('released');
      setTypeDialogData(false);
    }
  }, [typeDialogData]);

  const handlePostProductStatusChange = async (value) => {
    setstatus(value);
    console.log(group.name);
    var newgroup = JSON.parse(JSON.stringify(group));
    value === 'released'
      ? (newgroup.production = true)
      : (newgroup.production = false);
    delete newgroup._id;

    const resp = await updateProduct({
      id: group._id,
      group: { group: { ...newgroup, type: productType } },
    }).unwrap();
    if (resp.success) {
      update('production', newgroup.production, index);
    } else {
      setAlert({
        show: true,
        severity: 'error',
        msg: resp.msg,
      });
    }

    if (value === 'released') {
      const res = await tempReleaseProduct({
        _id: group._id,
        type: productType,
      }).unwrap();
    }
  };

  // useEffect(() => {
  //   status === 'released'
  //     ? setTypeDialogOpen(true)
  //     : handlePostProductStatusChange();
  // }, [status]);

  const openTypeDialog = async () => {
    setTypeDialogOpen(true);
    const t = await getProductType(group._id).unwrap();
    const v = await validateProduct(group._id).unwrap();
    console.log(v);
    console.log(t);
    setvalidationInfo(v);
    setproductType(t.type);
  };

  const handleProductStatusChange = async (value) => {
    console.log(group.id);
    console.log(value);
    // setstatus(value);
    handleClose();
    value === 'released'
      ? await openTypeDialog()
      : await handlePostProductStatusChange(value);
  };

  const handleTypeChange = (item, value) => {
    console.log(value);
    setproductType((current) => {
      return {
        ...current,
        [item.split('_')[0]]: {
          ...current[item.split('_')[0]],
          [item.split('_')[1]]: value,
        },
      };
    });
  };

  const handleProductFeaturedChange = (value) => {
    setfeatured(value);
    var newgroup = JSON.parse(JSON.stringify(group));
    value === 'yes' ? (newgroup.featured = true) : (newgroup.featured = false);
    delete newgroup._id;
    updateSubCategory(newgroup, group.name).then((resp) => {
      if (resp.success) {
        update('featured', newgroup.featured, index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  const handleProductPriceChange = (value) => {
    setprice(value);
    var newgroup = JSON.parse(JSON.stringify(group));
    newgroup.price = value;
    updateSubCategory(newgroup, group.name).then((resp) => {
      if (resp.success) {
        update('price', value, index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  const handleProductCategoryChange = (value) => {
    setcategory(value);
    changeSubCategoryParent(group.name, value).then((resp) => {
      if (resp.success) {
        update('delete', '', index);
        var temp_group = { ...group };
        temp_group.parent = value;
        handleMoveProduct(temp_group, cat_index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  const showToast = () => {
    setAlert({
      show: true,
      severity: 'error',
      msg: 'resp.msg',
    });
  };

  return (
    <div>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <IconButton aria-label="open" onClick={handleClick} variant="contained">
        <MenuOpenIcon fontSize="inherit" sx={{ color: '#fff' }} />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setRenameSubCategoryOpen(true)} disableRipple>
          <CreateIcon />
          Rename
        </MenuItem>
        <MenuItem onClick={() => setdeletePromptOpen(true)} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem>
        <SmallDialog
          title={`You're about to delete product ${group.name}`}
          content={`Are you sure you want to delete this amazing product?`}
          open={deletePromptOpen}
          setOpen={setdeletePromptOpen}
          setData={setdeletePromptData}
          cancelButton="Cancel"
          confirmButton="Delete"
          blocked={false}
        ></SmallDialog>
        <MenuItem disableRipple>
          <ProductImages name={group.name} />
        </MenuItem>
        <MenuItem disableRipple>
          <ProductVideo name={group.name} id={group._id} />
        </MenuItem>
        <SmallDialog
          title="Rename subcategory"
          content=""
          open={renameSubCategoryOpen}
          setOpen={setRenameSubCategoryOpen}
          setData={setRenameSubCategoryData}
          cancelButton="Cancel"
          confirmButton="Rename"
          blocked={false}
        >
          <TextField
            margin="normal"
            id="promptName"
            label="name"
            name="name"
            value={subCategoryName}
            onChange={handleSubCategoryNameChange}
            className="ring-0"
            sx={{ width: '300px' }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />
        </SmallDialog>
        {/* <MenuItem onClick={handleRemoveCategory} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem> */}
        <MenuItem disableRipple>
          <Dropdown
            name="category"
            value={category}
            setValue={handleProductCategoryChange}
            items={cats}
            width="140px"
          />
        </MenuItem>
        <MenuItem disableRipple>
          <Dropdown
            name="status"
            value={status}
            setValue={handleProductStatusChange}
            items={['released', 'not released']}
            width="140px"
          />
        </MenuItem>
        <MenuItem disableRipple>
          <Dropdown
            name="featured"
            value={featured}
            setValue={handleProductFeaturedChange}
            items={['yes', 'no']}
            width="140px"
          />
        </MenuItem>
      </StyledMenu>
      <SmallDialog
        title={'RELEASE SETTINGS'}
        content={''}
        open={typeDialogOpen}
        setOpen={setTypeDialogOpen}
        setData={setTypeDialogData}
        confirmButton="release"
        cancelButton="cancel"
        blocked={false}
      >
        <Typography
          sx={{ paddingTop: '0px', paddingBottom: '20px' }}
        >{`modify type:`}</Typography>
        {product_types_names.map((item) => {
          return (
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="space-beetween"
              flexDirection="row"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        productType[item.split('_')[0]][item.split('_')[1]]
                      }
                      onChange={(e) => handleTypeChange(item, e.target.checked)}
                      color="primary"
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: 'green', // Example: change the switch knob color when checked
                          '& + .MuiSwitch-track': {
                            backgroundColor: 'lightgreen', // Change the background of the switch track when checked
                          },
                        },
                      }}
                    />
                  }
                  label={item.replace('_', ' ')}
                  labelPlacement="start"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-beetween',
                    alignItems: 'center',
                    width: '150px',
                    textAlign: 'left',
                    marginLeft: '0px',
                  }}
                />
              </FormGroup>
            </Box>
          );
        })}
        <Divider></Divider>
        <Typography
          sx={{ paddingTop: '20px', paddingBottom: '20px' }}
        >{`validation info:`}</Typography>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
          width=" 300px"
        >
          {Object.keys(validationInfo).map((key, index) => {
            if (key !== 'prompts') {
              return (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    gap="10px"
                    key={key}
                    width="300px"
                    borderBottom="1px solid #5c5d6a"
                  >
                    <Typography>
                      {key === 'featured' || key === 'images'
                        ? `${key} field:`
                        : `${key}:`}
                    </Typography>
                    <Typography>{`${validationInfo[key]}`}</Typography>
                  </Box>

                  {key === 'images' && <br></br>}
                </>
              );
            } else {
              return (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    gap="10px"
                    key={key}
                    width="300px"
                    borderBottom="1px solid #5c5d6a"
                  >
                    <Typography>{`${key}:`}</Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap="10px"
                      key={key}
                      width="120px"
                    >
                      {Object.keys(validationInfo[key]).map((keyx) => {
                        return (
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            gap="10px"
                            key={key}
                          >
                            <Typography>{keyx}</Typography>
                            <Typography>{validationInfo[key][keyx]}</Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  <Divider></Divider>
                  <br></br>
                </>
              );
            }
          })}
        </Box>
      </SmallDialog>
    </div>
  );
}
