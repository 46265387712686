import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  ImageListItemBar,
  Typography,
} from '@mui/material';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { useGetSingleOrderMutation, useUpscaleMutation } from 'api/apiSlice';
import ImageDialog from 'components/ImageDialog';
import AutoRefreshImage from 'components/AutoRefreshImage';
import {
  CheckCircle,
  Circle,
  CircleOutlined,
  Download,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils';
import { useTheme } from '@mui/material/styles';

const Order = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const loadedPrompt = useSelector((state) => state.generate.loadedPrompt);
  const username = useSelector((state) => state.authentication.email);
  const { orderId } = useParams();
  const location = useLocation();
  const [getSingleOrder, { isLoading }] = useGetSingleOrderMutation();
  const [upscale] = useUpscaleMutation();
  const [promptName, setpromptName] = useState();
  const [createdAt, setcreatedAt] = useState();

  const [images, setimages] = useState([]);
  const [batch, setbatch] = useState();
  const [selectedImage, setselectedImage] = useState();
  const [open, setOpen] = useState(false);
  const [job, setjob] = useState('');
  const [isSelectMode, setisSelectMode] = useState(false);
  const [selectedImages, setselectedImages] = useState([]);

  const imageWidth = 240;
  useEffect(() => {
    document.title = orderId;
    getSingleOrder(orderId)
      .unwrap()
      .then((result) => {
        console.log(result.data);
        setimages(result.data.images);
        setbatch(result.data.batch_size + 1);
        setpromptName(result.data.product);
        setcreatedAt(formatDate(parseInt(result.data.finished_at * 1000)));
        setjob(Object.keys(result.data.jobs)[0]);
      });
  }, []);

  const handleOpenDialog = (index, item) => {
    if (!isSelectMode) {
      setOpen(true);
      setselectedImage(index);
    } else {
      if (item.includes('images')) {
        setselectedImages((current) =>
          current.includes(index)
            ? current.filter((item) => item !== index)
            : [...current, index]
        );
      }
    }
  };
  const handlesetIsSelectMode = () => {
    setselectedImages([]);
    setisSelectMode(!isSelectMode);
  };

  const handleSaveImage = (event, item) => {
    event.stopPropagation();
    fetch(item, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Credentials': true,
      },
      credentials: 'include',
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const urlx = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = urlx;
          const x = item.split('/').slice(-2);
          const filename = `${loadedPrompt.parent}_${loadedPrompt.name}.png`;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadPrompt = () => {
    navigate(`/generate?order=${job}`);
  };

  const handleUpscale = async () => {
    const selected = images.filter((item, index) =>
      selectedImages.includes(index)
    );
    console.log(selected);

    const resp = await upscale({
      order_id: orderId,
      username: username,
      promptName: loadedPrompt.name,
      urls: selected,
    }).unwrap();
    var nw = window.open(`/order/${resp.data.uuid}`, '_blank');
    nw.blur();
    window.focus();
  };

  return (
    <>
      <Box padding="50px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          width="100%"
          height="30px"
          gap="20px"
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignContent="center"
            width="350px"
            gap="20px"
            height="23px"
          >
            <Button
              variant="contained"
              sx={{
                alignSelf: 'center',
                height: '30px',
                backgroundColor: isSelectMode
                  ? '#fecd27'
                  : theme.palette.primary[100], // Color on hover
                color: isSelectMode ? '#000' : '#111',
                '&:hover': {
                  backgroundColor: isSelectMode
                    ? theme.palette.yellows[700]
                    : theme.palette.primary[300], // Color on hover
                },
              }}
              onClick={handlesetIsSelectMode}
            >
              {isSelectMode ? 'deselect' : 'select'}
            </Button>
            {isSelectMode && (
              <Typography>{selectedImages.length} images selected</Typography>
            )}
            {selectedImages.length !== 0 && (
              <Button
                variant="contained"
                sx={{
                  alignSelf: 'center',
                  height: '30px',
                }}
                onClick={handleUpscale}
              >
                UPSCALE
              </Button>
            )}
          </Box>
          <Typography sx={{ fontSize: '28px' }}>
            {promptName} - {createdAt}
          </Typography>
          <Box width="200px"></Box>
          {/* <Button
            variant="contained"
            sx={{
              alignSelf: 'center',
              height: '30px',
            }}
            onClick={loadPrompt}
          >
            LOAD
          </Button> */}
        </Box>

        <ImageList
          cols={batch}
          rowHeight={150}
          gap="10px"
          sx={{ width: `${batch * imageWidth + batch * 20}px` }}
        >
          {images.map((item, index) => (
            <>
              <ImageListItem
                key={item}
                onClick={() => handleOpenDialog(index, item)}
                sx={{
                  cursor: 'pointer',
                  width: `${240}px`,
                  height: `${240}px`,
                }}
                actionIcon={
                  <IconButton>
                    <Download />
                  </IconButton>
                }
              >
                {isSelectMode ? (
                  selectedImages.includes(index) ? (
                    <Box
                      sx={{
                        backgroundColor: '#858585be',
                        width: '50px',
                        height: '50px',
                        position: 'absolute',
                        zIndex: 999,
                        top: '0px',
                        right: '10px',

                        borderBottomLeftRadius: '40px',
                      }}
                    >
                      <CheckCircle
                        sx={{
                          color: '#fff',
                          fontSize: '30px',
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                        }}
                      />
                    </Box>
                  ) : (
                    item.includes('images') && (
                      <Box
                        sx={{
                          backgroundColor: '#858585be',
                          width: '50px',
                          height: '50px',
                          position: 'absolute',
                          zIndex: 999,
                          top: '0px',
                          right: '10px',

                          borderBottomLeftRadius: '40px',
                        }}
                      >
                        <CircleOutlined
                          sx={{
                            color: '#fff',
                            fontSize: '30px',
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                          }}
                        />
                      </Box>
                    )
                  )
                ) : (
                  <></>
                )}

                <AutoRefreshImage
                  src={item}
                  alt={'file missing'}
                  loading="lazy"
                  width="230"
                  height="230"
                />
                {!isSelectMode && item.includes('images') && (
                  <ImageListItemBar
                    sx={{
                      background:
                        'linear-gradient(to top, rgba(0,0,0,0.9) 0%, ' +
                        'rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.1) 100%)',

                      maxWidth: '230px',
                      marginBottom: '10px',
                    }}
                    title={item.title}
                    position="bottom"
                    actionIcon={
                      <>
                        <IconButton
                          sx={{
                            color: 'white',
                          }}
                          aria-label={`download`}
                          onClick={(event) => handleSaveImage(event, item)}
                        >
                          <Download />
                        </IconButton>
                      </>
                    }
                    actionPosition="right"
                  />
                )}
              </ImageListItem>
            </>
          ))}
        </ImageList>
        <ImageDialog
          open={open}
          setOpen={setOpen}
          images={images}
          index={selectedImage}
        />
      </Box>
    </>
  );
};

export default Order;
