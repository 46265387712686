import { useState, useEffect } from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

import { IconButton, TextField } from '@mui/material';

import { useCms } from '../../utils/CMS';
import SmallDialog from 'components/SmallDialog';
import Toast from 'components/Toast';
import Dropdown from 'components/Dropdown';

import {
  speciesValues,
  genderValues,
  productionValues,
} from '../../utils/data';
import { useDispatch } from 'react-redux';
import { setLoadedPrompt } from 'state/generate';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 80,
    backgroundColor: '#383838',
    color:
      theme.palette.mode === 'light'
        ? 'rgb(255, 255, 255)'
        : 'rgb(255, 255, 255)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: 'rgb(255, 255, 255)',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function PromptMenu({
  row_data,
  setTree,
  species,
  gender,
  isProduction,
  index,
  update,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { getTree, renamePrompt, removePrompt, updatePrompt } = useCms();
  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const [renamePromptOpen, setRenamePromptOpen] = useState(false);
  const [renamePromptData, setRenamePromptData] = useState(false);
  const [promptName, setPromptName] = useState(row_data.name);

  const [deletePromptOpen, setdeletePromptOpen] = useState(false);
  const [deletePromptData, setdeletePromptData] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // CATEGORIES ADD
  useEffect(() => {
    if (renamePromptData) {
      handleClose();
      renamePrompt(row_data.name, promptName).then((resp) => {
        if (resp.success) {
          setAlert({
            show: true,
            severity: 'success',
            msg: 'prompt name changed',
          });
          update(row_data.name, 'name', promptName);
          setRenamePromptData(false);
          dispatch(setLoadedPrompt({ key: 'name', data: promptName }));
        } else {
          setAlert({
            show: true,
            severity: 'error',
            msg: resp.msg,
          });
        }
      });
    } else {
      setAnchorEl(null);
    }
  }, [renamePromptData]);

  const handlePromptNameChange = (event) => {
    setPromptName(event.target.value);
  };

  useEffect(() => {
    setAnchorEl(null);
    if (deletePromptData) {
      handleRemovePrompt();
    }
  }, [deletePromptData]);

  const handleRemovePrompt = () => {
    removePrompt(row_data.name).then((resp) => {
      if (resp.success) {
        update(index, 'del', row_data.name);
      }
    });
  };

  const handleChangeSpecies = (value) => {
    setAnchorEl(null);
    const p = { name: row_data.name, _id: row_data._id, species: value };
    updatePrompt(p).then((resp) => {
      if (resp.success) {
        setAlert({
          show: true,
          severity: 'success',
          msg: 'species changed',
        });
        update(index, 'species', value);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };
  const handleChangeGender = (value) => {
    setAnchorEl(null);
    const p = { name: row_data.name, _id: row_data._id, gender: value };
    updatePrompt(p).then((resp) => {
      if (resp.success) {
        setAlert({
          show: true,
          severity: 'success',
          msg: 'gender changed',
        });
        update(index, 'gender', value);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };
  const handleChangeProductionStatus = (value) => {
    setAnchorEl(null);
    const p = {
      name: row_data.name,
      _id: row_data._id,
      isProduction: value,
    };
    updatePrompt(p).then((resp) => {
      if (resp.success) {
        setAlert({
          show: true,
          severity: 'success',
          msg: 'status changed',
        });
        update(row_data.name, 'isProduction', value);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  return (
    <div>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <IconButton aria-label="open" onClick={handleClick} variant="contained">
        <MenuOpenIcon fontSize="inherit" sx={{ color: '#fff' }} />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          backdropFilter: 'blur(2px)',
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.alt, // Change background color here
          },
        }}
      >
        <MenuItem onClick={() => setRenamePromptOpen(true)} disableRipple>
          <CreateIcon />
          Rename prompt
        </MenuItem>
        <SmallDialog
          title="Rename prompt"
          content=""
          open={renamePromptOpen}
          setOpen={setRenamePromptOpen}
          setData={setRenamePromptData}
          cancelButton="Cancel"
          confirmButton="Rename"
          blocked={false}
        >
          <TextField
            margin="normal"
            id="promptName"
            label="name"
            name="name"
            value={promptName}
            onChange={handlePromptNameChange}
            className="ring-0"
            sx={{ width: '300px' }}
            size="small"
            onKeyDown={(e) => e.stopPropagation()}
          />
        </SmallDialog>
        <MenuItem
          onClick={() => {
            setdeletePromptOpen(true);
          }}
          disableRipple
        >
          <DeleteIcon />
          Delete prompt
        </MenuItem>
        <SmallDialog
          title="Delete prompt"
          content="Are you sure you want to delete this amazing prompt?"
          open={deletePromptOpen}
          setOpen={setdeletePromptOpen}
          setData={setdeletePromptData}
          cancelButton="Cancel"
          confirmButton="Delete"
          blocked={false}
        ></SmallDialog>
        <MenuItem disableRipple>
          <Dropdown
            name="production"
            value={isProduction}
            setValue={handleChangeProductionStatus}
            items={productionValues}
          />
        </MenuItem>
        <MenuItem disableRipple>
          <Dropdown
            name="species"
            value={species}
            setValue={handleChangeSpecies}
            items={speciesValues}
          />
        </MenuItem>
        <MenuItem disableRipple>
          <Dropdown
            name="gender"
            value={gender}
            setValue={handleChangeGender}
            items={genderValues}
          />
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
